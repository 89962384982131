import 'leaflet';
import '@maplibre/maplibre-gl-leaflet';
import 'maplibre-gl';
import 'leaflet.markercluster';
import { GeoHash } from 'geohash';
import { interpolateViridis, scaleLinear, interpolateRgb } from 'd3';

export function createMap() {
    const MAP_ID = "registered_office_location_map";
    // check map container exists
    if (document.getElementById(MAP_ID) === null) {
        return;
    }
    const MAPSTYLE = "/static/mapstyle.json";
    const UK_BOUNDS = [
        [60.86, 1.76],
        [49.86, -8.65]
    ]
    location_map = L.map(MAP_ID, {
        zoomSnap: 0.1,
    });
    L.maplibreGL({
        style: MAPSTYLE,
        attribution: `
    <a href="https://openfreemap.org" target="_blank">OpenFreeMap</a> 
    <a href="https://www.openmaptiles.org/" target="_blank">&copy; OpenMapTiles</a> 
    Data from <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>`,
    }).addTo(location_map);
    location_map.fitBounds(UK_BOUNDS, {
        paddingBottomRight: [0, 15]
    })
    location_map.setMinZoom(location_map.getZoom());
    location_map.setMaxZoom(12);
    if (CZ_DATA["chart_data"]["geodata"].length < 100) {
        var markers = L.markerClusterGroup({
            maxClusterRadius: 30,
            singleMarkerMode: true,
        });
        CZ_DATA["chart_data"]["geodata"].forEach((v) => {
            if (v.location === null) {
                return;
            }
            var popupContent = `<a href="/charity/${v.org_id}/" target="_blank">${v.name}</a>`;
            L.marker([v.location.lat, v.location.lon]).bindPopup(popupContent).addTo(markers);
        });
        markers.addTo(location_map);
    } else {
        // use geohash buckets instead
        var values = CZ_DATA["chart_data"]["geohash"].map((v) => v.value);
        var max_value = Math.max(...values);
        var median_value = values.sort((a, b) => a - b)[Math.floor(values.length / 2)];
        // const color = scaleQuantile(values, [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]);
        const color = scaleLinear([0, median_value, max_value], ["#f8f8fb", "#6a6b98", "#141d44"]);
        const opacity = scaleLinear([0, max_value], [0.6, 0.9]);
        const radius_adjustment = scaleLinear([0, max_value], [0.4, 1.5]);
        // const interpolator = interpolateViridis(0, max_value);
        CZ_DATA["chart_data"]["geohash"].forEach((v) => {
            var value = GeoHash.decodeGeoHash(v.label);
            var radius = location_map.distance([value.latitude[2], value.longitude[2]], [value.latitude[0], value.longitude[0]]) / 2;
            var polygon = L.polygon([
                [value.latitude[0], value.longitude[1]],
                [value.latitude[0], value.longitude[0]],
                [value.latitude[1], value.longitude[0]],
                [value.latitude[1], value.longitude[1]],
            ], {
                stroke: false,
                fillColor: color(v.value),
                fillOpacity: opacity(v.value)
            });
            // var polygon = L.circle([
            //     value.latitude[2], value.longitude[2]
            // ], {
            //     radius: radius * radius_adjustment(v.value),
            //     stroke: false,
            //     fillColor: color(v.value),
            //     fillOpacity: 1,
            // });
            polygon.bindPopup(`${v.value} charities`);
            polygon.addTo(location_map);
        });
    }
}