import { default as Chart, MONEY, czPieChart, czHorizontalBarChart, czVerticalBarChart } from './charts.js';

var MAX_LENGTH = 100;

function getIncomeData(max_length = MAX_LENGTH, sort_by = "size") {
    var income_data = CZ_DATA["chart_data"]["income_expenditure"];
    if (income_data.length > max_length) {
        income_data = income_data.map(a => [a, Math.random()])
            .sort((a, b) => { return a[1] < b[1] ? -1 : 1; })
            .slice(0, max_length)
            .map(a => a[0]);
    }
    return income_data;
}

export default function createCharts() {
    if (!CZ_DATA["chart_data"]) { return }
    var income_data = getIncomeData();

    var ctx_income_expenditure = document.getElementById('income_expenditure_chart');
    var chart_income_expenditure = new Chart(ctx_income_expenditure, {
        type: 'bar',
        data: {
            labels: income_data.map((v) => v.name),
            datasets: [{
                label: 'Income',
                data: income_data.map((v) => v.latest_income),
                borderWidth: 0,
                borderRadius: 20,
                stack: 1,
                backgroundColor: '#7777a5',
                //barThickness: 15,
            }, {
                label: 'Spending',
                data: income_data.map((v) => -v.latest_spending),
                borderWidth: 0,
                borderRadius: 20,
                stack: 1,
                backgroundColor: '#141c44',
                //barThickness: 15,
            }]
        },
        options: {
            responsive: false,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            let label = context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += MONEY.format(Math.abs(context.parsed.y));
                            }
                            return label;
                        }
                    }
                }
            },
            scales: {
                y: {
                    stacked: true,
                    grid: {
                        display: false,
                    },
                    // type: 'logarithmic',
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function (value, index, ticks) {
                            var max_tick = Math.max(...ticks.map((tick) => Math.abs(tick.value)));
                            var return_value = Math.abs(value);
                            var suffix = '';
                            if (max_tick > 1000000000) {
                                return_value = return_value / 1000000000;
                                suffix = 'bn';
                            } else if (max_tick > 1000000) {
                                return_value = return_value / 1000000;
                                suffix = 'm';
                            } else if (max_tick > 1000) {
                                return_value = return_value / 1000;
                                suffix = 'k';
                            }
                            return MONEY.format(return_value) + suffix;
                        }
                    }
                },
                x: {
                    display: false,
                }
            }
        }
    });

    document.querySelector("#income-expenditure-sort").addEventListener("change", function (e) {
        if (e.target.value === "name") {
            var data = CZ_DATA["chart_data"]["income_expenditure"].map((v) => v);
            data.sort((a, b) => a.name.localeCompare(b.name))
            data = data.slice(0, MAX_LENGTH);
        } else if (e.target.value === "size") {
            var data = CZ_DATA["chart_data"]["income_expenditure_size"].map((v) => v);
            data.sort((a, b) => b.latest_spending - a.latest_spending);
            data = data.slice(0, MAX_LENGTH);
        } else {
            var data = getIncomeData();
        }
        chart_income_expenditure.data.datasets[0].data = data.map((v) => v.latest_income);
        chart_income_expenditure.data.datasets[1].data = data.map((v) => -v.latest_spending);
        chart_income_expenditure.data.labels = data.map((v) => v.name);
        chart_income_expenditure.update();
    });
    document.querySelector("#income-expenditure-sort").value = "size";
    document.querySelector("#income-expenditure-sort").dispatchEvent(new Event('change', { 'bubbles': true }));

    var ctx_trustees = document.getElementById('trustees_chart');
    var trustees_legend = document.createElement('div');
    trustees_legend.id = 'trustees_legend';
    ctx_trustees.parentNode.insertBefore(trustees_legend, ctx_trustees.nextSibling);
    var chart_trustees = czPieChart(ctx_trustees, CZ_DATA["chart_data"]["trustees"], trustees_legend.id);

    var ctx_volunteers = document.getElementById('volunteers_chart');
    var volunteers_legend = document.createElement('div');
    volunteers_legend.id = 'volunteers_legend';
    ctx_volunteers.parentNode.insertBefore(volunteers_legend, ctx_volunteers.nextSibling);
    var chart_volunteers = czPieChart(ctx_volunteers, CZ_DATA["chart_data"]["volunteers"], volunteers_legend.id);

    var ctx_annual_return_submission_on_time = document.getElementById('annual_return_submission_on_time_chart');
    var chart_annual_return_submission_on_time = czHorizontalBarChart(
        ctx_annual_return_submission_on_time,
        CZ_DATA["chart_data"]["annual_return_submission_on_time"]
    );

    var ctx_imd_decile = document.getElementById('imd_decile_chart');
    var chart_imd_decile = czVerticalBarChart(
        ctx_imd_decile,
        CZ_DATA["chart_data"]["imd_decile"]
    );
    chart_imd_decile.options.scales.x.title = {
        display: true,
        text: 'IMD Decile',
    }
    chart_imd_decile.options.scales.y.title = {
        display: true,
        text: 'Number of charities',
    }
    chart_imd_decile.update();

    var ctx_area_of_operations_in_the_uk = document.getElementById('area_of_operations_in_the_uk_chart');
    var chart_area_of_operations_in_the_uk = czHorizontalBarChart(
        ctx_area_of_operations_in_the_uk,
        CZ_DATA["chart_data"]["area_of_operations_in_the_uk"]
    );
}